
import {
  Component,
  Vue
} from 'vue-property-decorator'
import lazyLoad from '@/components/LazyLoad'
  @Component({
    name: 'BowserDispatch',
    components: {}
  })
export default class BowserDispatch extends Vue {
    protected isDelivered = false

    get component () {
      if (this.isDelivered) {
        return () => lazyLoad(import('./Bowser/Delivery.vue'))
      }

      if (!this.isDelivered) {
        return () => lazyLoad(import('./Bowser/Progress.vue'))
      }
    }
}

